import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ModalContext } from "../../context/ModalContext";

const Cooperation = () => {
  const { t } = useTranslation();
  const { openModal, setOpenModal } = useContext(ModalContext);

  const modelContent = [
    {
      title: "team",
      text: `${t("services.engTeam.cooperation.ewateam.text")}`,
      ourText: `${t("services.engTeam.cooperation.ewateam.ourText")}`,
      yourText: `${t("services.engTeam.cooperation.ewateam.yourText")}`,
      buttonText: `${t("buttons.buildTeam")}`,
    },
    {
      title: "partner",
      text: `${t("services.engTeam.cooperation.ewapartner.text")}`,
      ourText: `${t("services.engTeam.cooperation.ewapartner.ourText")}`,
      yourText: `${t("services.engTeam.cooperation.ewapartner.yourText")}`,
      buttonText: `${t("buttons.buildPartnership")}`,
    },
  ];

  const benefitsContent = [
    {
      title: `${t("services.engTeam.cooperation.benefitsTeam.title")}`,
      titleSpan: `${t("services.engTeam.cooperation.benefitsTeam.titleSpan")}`,
      firstText: `${t("services.engTeam.cooperation.benefitsTeam.firstText")}`,
      secondText: `${t(
        "services.engTeam.cooperation.benefitsTeam.secondText"
      )}`,
      thirdText: `${t("services.engTeam.cooperation.benefitsTeam.thirdText")}`,
      fourthText: `${t(
        "services.engTeam.cooperation.benefitsTeam.fourthText"
      )}`,
    },
    {
      title: `${t("services.engTeam.cooperation.benefitsPartner.title")}`,
      titleSpan: `${t(
        "services.engTeam.cooperation.benefitsPartner.titleSpan"
      )}`,

      firstText: `${t(
        "services.engTeam.cooperation.benefitsPartner.firstText"
      )}`,
      secondText: `${t(
        "services.engTeam.cooperation.benefitsPartner.secondText"
      )}`,
      thirdText: `${t(
        "services.engTeam.cooperation.benefitsPartner.thirdText"
      )}`,
      fourthText: `${t(
        "services.engTeam.cooperation.benefitsPartner.fourthText"
      )}`,
    },
  ];
  return (
    <section className="m-cooperation">
      <div className="_wr">
        <div className="m-cooperation__top">
          <h2 className="-thin">
            {t("services.engTeam.cooperation.headline")}
          </h2>
          <span className="a-line -red"></span>
        </div>

        <div className="_w -center">
          {modelContent.map(
            ({ title, text, ourText, yourText, buttonText }, i) => {
              return (
                <div
                  className="_m6 m-cooperation__cardContent -mb"
                  key={i}
                  onClick={setOpenModal}
                >
                  <div className="m-cooperation__card">
                    <div className="m-cooperation__card--header">
                      <h3 className="m-cooperation__card--title">
                        ewa<span>{title}</span>
                      </h3>
                      <p className="m-cooperation__card--textTop">{text}</p>
                    </div>
                    <div className="m-cooperation__card--flex">
                      <div className="m-cooperation__card--box -first">
                        <p className="m-cooperation__card--subtitle">
                          {t("services.engTeam.cooperation.ourTitle")}
                        </p>
                        <p>{ourText}</p>
                      </div>

                      <div className="m-cooperation__card--box">
                        <p className="m-cooperation__card--subtitle">
                          {t("services.engTeam.cooperation.yourTitle")}
                        </p>
                        <p>{yourText}</p>
                      </div>
                    </div>
                    <div className="m-cardLinks">
                      <span
                        className="a-btn -tertiary -offer"
                        onClick={setOpenModal}
                      >
                        {buttonText}
                      </span>
                      <span className="a-arrow -long"></span>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>

        <div className="_w -center m-cooperation__bottom">
          {benefitsContent.map(
            (
              {
                title,
                firstText,
                secondText,
                thirdText,
                fourthText,
                titleSpan,
              },
              i
            ) => {
              return (
                <div className="_l6 -mbb m-cooperation__bottom--block" key={i}>
                  <h3 className="m-cooperation__bottom--title">
                    {title}
                    <span>{titleSpan}</span>
                  </h3>
                  <div className="m-cooperation__bottom--flex">
                    <span className="a-circle"></span>
                    <span className="m-cooperation__bottom--text">
                      {firstText}
                    </span>
                  </div>
                  <div className="m-cooperation__bottom--flex">
                    <span className="a-circle"></span>
                    <span className="m-cooperation__bottom--text">
                      {secondText}
                    </span>
                  </div>
                  <div className="m-cooperation__bottom--flex">
                    <span className="a-circle"></span>
                    <span className="m-cooperation__bottom--text">
                      {thirdText}
                    </span>
                  </div>
                  <div className="m-cooperation__bottom--flex">
                    <span className="a-circle"></span>
                    <span className="m-cooperation__bottom--text">
                      {fourthText}
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default Cooperation;
