import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Text from "../../components/global/text";
import Projects from "../../components/global/projects";
import Develop from "../../components/global/develop";
import HeaderGlobal from "../../components/headers/header-global";
import { graphql } from "gatsby";
import Process from "../../components/global/process";
import Cooperation from "../../components/engineering-team/cooperation";
import HeaderBcg from "../../assets/images/headers/e-team.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const EngineeringTeam = () => {
  const { t } = useTranslation();

  // Text Component
  const textContent = {
    title: `${t("services.engTeam.howDoesItWork.title")}`,
    text: `${t("services.engTeam.howDoesItWork.firstText")}`,
    secondText: `${t("services.engTeam.howDoesItWork.secondText")}`,
  };

  const textContent2 = {
    title: `${t("services.engTeam.ourApproach.headline")}`,
    text: `${t("services.engTeam.ourApproach.text")}`,
  };

  // Header Content
  const hTitle = `${t("services.engTeam.headline")}`;
  const hTextOne = `${t("services.engTeam.firstText")}`;

  // Process Component
  const processShortText = `${t("services.appDev.process.shortText")}`;

  const processContent = [
    {
      title: `${t("services.engTeam.process.first.title")}`,
      text: `${t("services.engTeam.process.first.text")}`,
    },
    {
      title: `${t("services.engTeam.process.second.title")}`,
      text: `${t("services.engTeam.process.second.text")}`,
    },
    {
      title: `${t("services.engTeam.process.third.title")}`,
      text: `${t("services.engTeam.process.third.text")}`,
    },
    {
      title: `${t("services.engTeam.process.fourth.title")}`,
      text: `${t("services.engTeam.process.fourth.text")}`,
    },
  ];

  return (
    <Layout>
      <Seo title="Engineering Team" />
      <HeaderGlobal hTitle={hTitle} hTextOne={hTextOne} image={HeaderBcg} />
      <Text textContent={textContent} rowWidth="_l12" cssModifer="-homeText" />
      <Cooperation />
      <Text
        textContent={textContent2}
        backgroundColor="-approach"
        cssModifer="-homeText"
      />
      <Process
        processContent={processContent}
        processShortText={processShortText}
        backgroundColor="-engineer"
      />
      <Projects cssModifer="-projectsHome" />;
      <Develop />
    </Layout>
  );
};

export default EngineeringTeam;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
